.danger{
    color: rgb(255,93,132) !important;
}
.warning{
    color: rgb(254,211,58) !important;
}
.success{
    color: rgb(60,213,97) !important;
}
.blue_light{
    color: #0490FE !important;
}
.bg_light{
    background-color: #FFFFFF;
}
.color_primary{
    color: #6B5CA8 !important;
}
$color-primary-dark: #384364;
$color_primary: #6B5CA8;
$lightPurple: #7E67D6;
$white: #FFFFFF;
$dark-grey: #888EA2;
$bg_light: #FAFBFF ;
$light_grey: #CFD2D8;

