@import'../../styles/global';


.login-form-forgot {
  float: right;
}

.smile-header{
  margin: 5em 4em;
  padding: 1.8em 1em;
  width: 65px;
  background-color: $color-primary;
  border-radius: 70px;
  -webkit-box-shadow: 0px 1px 34px 9px rgba(171, 141, 198, 0.3);
  -moz-box-shadow: 0px 1px 34px 9px rgba(171, 141, 198, 0.3);
  box-shadow:0px 1px 34px 9px rgba(171, 141, 198, 0.3);
  @media (max-width: 768px) {
    margin: 5em 2em;
  }
}
.lang-header{
  margin: 5em 4em;
  padding: 1.8em 1em;
  cursor: pointer;
  @media (max-width: 768px) {
    margin: 5em 2em;
  }
}
.login-form-button {
  width: 100%;
  color: $white;
  font-weight: 600;
  background: #7C6DB5;
  margin: 2em 0 !important;
  &:hover{
    background: #8070C3 !important;
    color: white;
    border: $color-primary-dark
  }
}
.logo-image{
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 5em;
  margin: 2em auto;
}
.login-section {
  margin: auto;
  max-width: 480px;
  align-content: center;
  padding: 13em 4em 0em 4em;
  .ant-typography {
    text-align: center;
  }

  .login-form{
    display: inline-block;
    align-items: center;
    margin: 0 auto;
  }

  .login-input{
    font-size: 14px;
    padding: .7em 1em;
    max-width: 420px;
    border:none !important;
  }
}
.--ReactInputVerificationCode-itemWidth{
  width: 2em;
}

.--ReactInputVerificationCode-itemHeight{

}