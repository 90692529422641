@import '../../../../src/styles/global';

.ant-layout-footer {
  padding: 0 !important;
  position: fixed;
  bottom: 0;
  width: 100vw;
}
#pointLayout{
  .ant-menu-item{
    text-align: center;
    width: 50%;
  }
}
.user-point-layout {
  background-position: top;
  height: 160px;
  background-color: $color-primary;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

}

.point-user-container {
  margin-top: -140px;
}

.title-text {
  color: white;
  font-size: 23pt;
  text-align: center;
}
