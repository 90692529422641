
@import "variables";
@import "helper";


.Typist 
{
    display: inline;
}
li.active > a
{
    color:#2f55d4 !important
}

.main-slider > .slides
{
    margin-left: 0!important;
    padding-left: 0!important;
}

.nav-link
{
    border-radius: 10px!important;
}

.pagination .active a {
    color: #ffffff!important;
}

a {
 &:hover {
  cursor:pointer;
 } 
}

.back-to-top
{
    line-height: 31px !important;
}
.progress-bar
{
    background-color: #2f55d4;
}

#mt-1
{
    margin-top: 5px!important;
}
#mb-1
{
    margin-top: 5px!important;
}