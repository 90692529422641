//
// variables.scss
//

// Color
$white: #ffffff;

$primary: #6B5CA8;
$secondary: #5a6d90;
$success: #2eca8b;
$warning: #f17425;
$info: #17a2b8;
$danger: #e43f52;
$dark: #384364;
$black: #161c2d;
$muted: #8492a6;
$light: #f8f9fc;

// Gray
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
// Overlay
$overlay: rgba(35, 37, 47, 0.7);
$gradident-overlay: rgba(47, 86, 212, 0.75);
// Body Background
$body-color: #161c2d;
$shadow: 0 0 3px rgba(0, 0, 0, .15);
// Base font
$font-size-base: 16px;

$font-family-base: 'Tajawal',
sans-serif;
$font-family-secondary: 'Tajawal',
sans-serif;

$colors: ("primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "info": $info,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
)