@import'../src/styles/global';
@import '../src/styles/scss/style';
@font-face {
    font-family: 'Tajawal';
    src: local('Tajawal'), url(./fonts/Tajawal-Regular.ttf) format('truetype');
}
*{
    font-family: Tajawal ;

}
body {
    background: $bg-dark;
}
.ant-layout{
    background-color: $bg-dark;
}
input {

    &:hover,
    &:focus {
        border: 1px solid rgba(147, 128, 222, 0.349) !important;
        -webkit-box-shadow: #9380DE !important;
        box-shadow: $color-primary !important;
    }
}

.background-light {
    background: $bg-dark;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.centered-vertical-align {
    align-items: center;
    justify-content: center;
}

.centered-v {
    display: flex;
    align-items: center;
    justify-content: start;
}
.centered-empty {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center ;
}

.centered {
    text-align: center;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.menu-item {
    &:active {
        background: none !important;
    }

    &:hover {
        color: $color-primary !important;
        background: #9380DE !important;
    }
}

.primary-button {
    color: $white;
    background-color: $color-primary;
}

.text-color-blue {
    color: $blue-light;
}

.text-color-primary {
    color: $color-primary;
}

.text-light-gray {
    color: #bbbdbb;
}

.text-color-white {
    color:white;
    &:hover{
        color: rgba(255, 255, 255, 0.78);
    }

}

.text-color-dark {
    color: $color-primary-dark !important;
}

.text-color-gray {
    color: $dark-gray !important;
}

.text-color-yello {
    color: $warninng !important;
}

.text-color-red {
    color: #FC6E8E;
}

.text-color-green {
    color: #3CD561 !important;
}
.marg-v-1{
    margin: 1em 0;
}
.marg-h-1{
    margin: 0 .4em 0 0 ;
}
.ant-tag{
    padding: 0 1em !important; ;
    border-radius: 16px;
    background-color: transparent;
}

.error-message {
    color: #ff6961;
    margin-inline-start: 0.5em;
    font-size: 10pt;
}

.error {
    border: 1.5px solid #ff6961 !important ;
}

.ant-select-selector {
    height: 50px !important;
}

.ant-select-selector-item {
    height: 50px !important;
    margin: auto;
    width: 50%;
    padding: 10px;
}

.ant-select-selector-search {
    height: 50px !important;
}

.ant-select-selection-search {
    height: 50px !important;
    justify-content: center;
    vertical-align: center;
    margin-top: .4em;
}

.ant-select-selection-search-input {
    margin: auto;
    width: 50%;
    padding: 10px;
}

.ma-ant-select-selection {
    background-color: #F2F2F3 !important;
    color: black;
    border-radius: 4px;
    padding: .8em .8em;
    margin-top: .5em;
    :focus,:hover{
        background: white;
        color: black;
    }
}

.editable-row .ant-form-explain {
    position: absolute;
    font-size: 12px;
    margin-top: -4px;
}

.section-container {
    background: white;
    padding: 2em 0;
}

.marg-2{
    margin: 2em auto;
}

//.ant-pagination-item a{
//    display: ruby-text-container !important;
//}

//.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-nex{
//    height: auto !important;
//}
.ant-table thead > tr > th{
    background: white;
}

.ant-table tbody > tr > td{
    border-bottom:none;
    margin: 2em 0;
}

.ant-table tbody > tr.ant-table-row:hover > td{
    background: #F9FAFF !important ;
}
.menu-item:hover{
    color: white;
    background-color: transparent !important;
}

.password-reset{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top:8%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

.forget-password-form{
    max-width: 480px;
    width: inherit;
    padding: 0 2em;
}


#components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.site-layout .site-layout-background {
    background: #fff;
}
.ant-input-search-icon::before{
    display: none;
}


.sider {
    height: 100vh;
    background: white;
    border-radius: 1px rgba(0, 0, 0, 0.20) !important;
}

.sider {
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    width: 300px !important;
}
.drawer-side-menu {
    .ant-drawer-body {
        flex-grow: 1;
        padding: 24px;
        overflow: auto;
        font-size: 14px;
        line-height: 1.5715;
        word-wrap: break-word;
        background-color: #37314f;
    }
}
.icon-fix{
    .anticon{
        margin:.3em;
        font-size: 14px;
    }

}
.cursor-click{
    cursor: pointer;
}
.ant-tabs-content.ant-tabs-content-top {
    margin-right: 0 !important;
    margin-left: 0 !important;
    z-index: 10000;
}
.ant-col.ant-col-rtl {
    float: none !important;
 }

.custom-date-picker {
    input:hover,
    input:focus {
        border: none !important;
        box-shadow: none !important;
    }

    @media (max-width: 768px) {
        .ant-picker-panel-container .ant-picker-panels {
            display: grid;
            flex-wrap: inherit;
            direction: ltr;
        }
    }
}

.all-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  p {
    text-align: justify;
  }
  .bottom-0 {
    bottom: 0;
  }
  .overflow-auto {
    overflow: auto;
  }