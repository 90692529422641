@import'../../styles/_global.scss';

.header-menu {
    width: available;

}

.float-end{
    float: right;
    display: inline-block;
}
// .ant-layout-header {
//     height: 64px;
//     line-height: 64px;
//     background: $bg-dark;
// }

.ant-dropdown-link {
    color: $color-primary-dark;

    &:hover {
        color: $color-primary-dark;
    }
}

.user-dropdown {
    justify-content: end;
}

.user-icon {
    margin-inline-end: 1em;
}