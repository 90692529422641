
@import './colors.scss';

.Overview-container {
    background-color: $bg_light;
}
.container{
    width:100%;
    padding: 20px;
    margin: auto;
}
.icon-cd-size{
    //font-size: 20px;
    padding: 0.5rem;
}
.cd-content{
    text-align: center;
    display: inline;
    font-size: 20pt;
}
.font-16px{
    font-size: 16px !important;
}
.font-20px{
    font-size: 20px;
}
.tx-aln-end{
    text-align: end ;
    align-content: flex-end;
}
.tx-aln-start{
    text-align: start ;
    align-content: flex-start;
}

.ht-15vh{
    height: 50vh;
}
.Pd-lr-30px{
    padding:0px 30px;
}
.Pd-lr-10px{
    padding:0px 10px !important;
}
.mg-top-5px{
    margin-top: 20px !important;
}
.mg-lr-10px{
    margin: 0px 10px;
}
.mg-lr-5px{
    margin: 0px 5px;
}
.mg-10px{
    margin: 10px;
}
.img-btn{
    color: $white !important;
    background-color: $lightPurple !important;
    border-color: $lightPurple !important;
}
.btn-tx{
    &:hover { border: 2px $lightPurple solid !important;
        color: $lightPurple !important; }
}
.space-between{
    justify-content: space-between !important;
}
.flex-container{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}
.mg-bottom-30px{
    margin-bottom: 70%;
}
.aln-center{
    text-align: center !important;
    display: flex;
    justify-content: center; 
    vertical-align: center;
}

.aln-left{
    text-align: left !important;
    display: flex;
    justify-content: center; 
}