@import './colors.scss';

.sider-layout{
  background: #001529;
  .smile-menu-header{
    margin: 30px .5em;
    padding: 1.8em 1em;
    width: 65px;
    background-color: $color_primary;
    border-radius: 10px;
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub{
    background: transparent !important;
  }
}

.header-wrapper{
  .ant-layout-header {
    background: #fff;
    padding: 0 !important;
    border-bottom: 1px solid #e9e9e9;

    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 576px) {
    .i18n-select {
      display: none;
    }
  }

  .user-dropdown {
    padding: 0 24px;
    cursor: pointer;
    display: inline-block;
    transition: all 0.3s;
    height: 100%;
    > i {
      vertical-align: middle;
      color: $dark-grey;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    :global(&.ant-popover-open) {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .user-dropdown-avatar {
    margin: 20px 8px 20px 0;
    vertical-align: top;
  }

  @media (max-width: 576px) {
    .user-dropdown-text {
      display: none;
    }
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: var(--primary-color);
    }
  }
}

.webview-header{
  background-color: $color_primary;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  height: 5rem;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
  .ant-page-header-heading-title {
    margin: 0 20px;
    margin-bottom: 0;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center !important;
    display: flex;
    justify-content: center;  
  }
  .ant-page-header-back-button {
    color: $color_primary;
    text-decoration: none;
    outline: none;
    transition: color 0.3s;
    color: #fff;
    cursor: pointer;
  }
  .ant-page-header-back {
    margin-right: 0px;
    font-size: 16px;
    line-height: 1;
}
}
.webview-footer{
  background-color: #fff;
  padding: 2px 15px;
  box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.2);
}
.webview-container{
  height: 100%;
  width:100%;
  padding: 15px 15px 0 15px;
  margin: auto;
}