@font-face {
  font-family: 'Tajawal' !important;
  src: local('Tajawal'), url(./fonts/Tajawal-Regular.ttf) format('truetype') !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
